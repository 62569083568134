<template>
    <router-link v-if="attributes.to" v-bind="attributes" v-on="listeners">
        <slot />
    </router-link>

    <a v-else-if="$attrs.href" v-bind="attributes" v-on="listeners">
        <slot />
    </a>

    <button v-else v-bind="attributes" v-on="listeners">
        <slot />
    </button>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        size: {
            type: String,
            required: false,
            default: () => "md",
        },
        color: {
            type: String,
            required: false,
            default: () => "primary",
        },
        disabled: {
            type: Boolean,
            required: false,
            default: () => false,
        },
        active: {
            type: Boolean,
            required: false,
            default: () => false,
        },
    },
    data() {
        return {
            /**
             * @todo Write a description.
             */
            baseClasses: "inline-flex text-center items-center justify-center border border-transparent disabled:opacity-75 disabled:cursor-not-allowed",

            /**
             * @todo Write a description
             */
            sizes: {
                xs: "px-2.5 py-1.5 text-xs font-medium rounded",
                sm: "px-3 py-2 text-sm leading-4 font-medium rounded-md",
                md: "px-4 py-2 text-sm font-medium rounded-md",
                lg: "px-4 py-2 text-base font-medium rounded-md",
                xl: "px-6 py-3 text-base font-medium rounded-md",
            },
        };
    },
    computed: {
        /**
         * The colors available for the button component.
         */
        colors() {
            return {
                /**
                 * @color Primary
                 */
                primary: `${!this.active ? "bg-primary-600" : "bg-primary-500"} hover:bg-primary-500 text-gray-50 border border-default`,
                primaryInverse: `${!this.active ? "bg-transparent" : "bg-primary-600 border-primary-600"} hover:bg-primary-600 text-gray-50 border border-primary-600 hover:border-primary-600 border-opacity-10`,

                /**
                 * @color Secondary
                 */
                secondary: "text-primary-700 bg-primary-100 hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 border border-default",
                secondaryInverse: `${!this.active ? "bg-transparent" : "bg-secondary-600 border-secondary-600"} hover:bg-secondary-600 text-gray-50 border border-secondary-600 hover:border-secondary-600 border-opacity-10`,

                /**
                 * @color Gray
                 */
                dark: "text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate",
                darkInverse: "",

                /**
                 * @color Light
                 */
                light: "",
                lightInverse: "",

                /**
                 * @color Danger
                 */
                danger: "text-white bg-tomato hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-tomato",

                /**
                 * @color Transparent
                 */
                transparent: `${!this.active ? "text-gray-50" : "text-primary-500"} hover:text-primary-500 bg-transparent border-none`,
                transparentInverse: "",

                /**
                 * @color Disabled
                 */
                disabled: "text-gray-400 bg-gray-900 text-gray-400 border cursor-not-allowed",
                disabledInverse: "",
            };
        },

        classes() {
            return `v-button ${this.baseClasses} ${this.sizes[this.size]} ${this.disabled ? this.colors.disabled : this.colors[this.color]} ${this.$attrs.class}`;
        },
        attributes() {
            return {
                class: this.classes,
                disabled: this.disabled,
                ...this.$attrs,
            };
        },
        listeners() {
            return {
                ...this.$listeners,
            };
        },
    },
};
</script>
